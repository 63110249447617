@import 'styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 22px;
    line-height: 30px;
    color: #33325C;
    margin-bottom: 20px;
  }
  .message {
    font-size: 12px;
    color: #8790B1;
    width: 60%;
    margin-bottom: 50px;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #F3F6FF;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 30px;
    & > div {
      font-size: 13px;
      color: #33325C;
      padding: 10px;
      border-right: 1px solid #d3ddfa;
      &:last-of-type {
        border: none;
      }
    }
  }
  .selectContainer {
    width: 60%;
    margin: 0 auto;
  }
  .description {
    margin: 5% 0;
  }
  .button {
    margin: 0 3% 3%;
  }
}
.successResponse {
  font-size: 14px;
  color: $success-color;
}
.wrongResponse {
  font-size: 14px;
  color: $danger-color;
}