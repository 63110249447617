$primary-color: #5C25D3;
$primary-dark-color: #3C25A4;
$secondary-color: #FE4844;
$white-color: #ffffff;
$background-color: #F9F9F9;
$success-color: #8DD91F;
$dark-success-color: #7CAE49;
$danger-color: #FF4843;
$mobile-header-height: 80px;
$header-height: 120px;
$mobile-header-bg: #fff;
$header-bg: #fff;
$footer-bg: #fff;
$footer-height: 150px;
$transition-duration: 0.3s;
$text-color: #000;
$text-color-inverted: #fff;
$transparent-color: transparent;
$facebook-icon-color: #4267B2;
$linkedIn-icon-color: #0173B1;
$default-color: #EFF0F3;
$light-gray-color: #F9F9F9;
$light-red-color: #ff48430f;
$dark-gray-color: #8790B1;

//Colors for pricing page
$text-color-2: #33325c;
$text-color-pale-2: #8790b1;

// Colors for circle buttons
$button-primary-color: $primary-color;
$button-danger-color: $danger-color;
$button-default-color: #B6B6B6;
$button-success-color: $dark-success-color;
$button-light-color: $white-color;

// Sizes for circle buttons
$button-size-sm: 30px;
$button-size-md: 50px;
$icon-size-sm: 12px;
$icon-size-md: 17px;

// fonts

$font-family: 'Lato', sans-serif;
$font-family-heading: 'Brutal Type', arial, sans-serif;


// Breakpoints
$xxxs: 320px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  primaryDarkColor: $primary-dark-color;
  secondaryColor: $secondary-color;
  whiteColor: $white-color;
  backgroundColor: $background-color;
  $successColor: $success-color;
  $dangerColor: $danger-color;
  facebookIconColor: $facebook-icon-color;
  linkedInIconColor: $linkedIn-icon-color;
  googleIconColor: $primary-color;
  successColor: $success-color;
}