@import 'styles/variables';

.container {
  width: 300px;
  height: 97%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  background-color: $primary-color;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: $text-color-inverted;
  
  ul {
    padding: 0;
    li {
      list-style: none;
      a {
        color: $text-color-inverted;
        &:hover,
        &:active,
        &:visited,
        &focus {
          color: $text-color-inverted;
        }
      }
    }
  }
}

.body {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navLink {
  font-size: 14px;
  width: 100%;
  display: inline-block;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
  .navLinkInner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: relative;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  &.activeNavLink {
    color: $primary-color !important;
    font-weight: bold;
    .navLinkInner {
      background-color: $background-color;
    }
    .topCurve, .bottomCurve {
      left: 0;
      background-color: $white-color;
      position: absolute;
      height: 15px;
      width: 100%;
      &::after {
        left: 0;
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $primary-color;
      }
    }
    .topCurve {
      top: -15px;
      &::after {
        border-bottom-right-radius: 15px;
      }
    }
    .bottomCurve {
      bottom: -15px;
      &::after {
        border-top-right-radius: 15px;
      }
    }
  }
}

.logoContainer {
  display: inline-block;
  padding-bottom: 20px;
  padding-top: 20px;
}

.logoutButton {
  font-size: 14px;
  width: 50%;
  
  color: #fff;
  background-color: transparent;
  border: none;
  margin: 0 auto 45px;
  outline: none;
  cursor: pointer;
}