.input {
  background: #FFFFFF;
  border: 1px solid #F2F5FE;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(51, 50, 92, 0.05);
  border-radius: 50px;
  width: 290px;
  height: 40px;
  outline: none;
  padding-left: 20px;
  color: #33325C;
  font-size: 14px!important;
  &::placeholder {
    font-size: 14px;
    align-items: center;
    color: #BFC3D7;
  }
  &:disabled {
    background-color: #F4F6FA;
  }
}