.listItemInfo {
  padding: 10px;
  .listItemTable {
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    align-items: center;

    color: #33325c;
    th {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      color: #8790b1;
    }
    tr {
      background: #f9f9f9;
    }
    td {
      background: #f9f9f9;
    }
  }
  .businessContactsTable {
      width: 100%;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
  
      align-items: center;
  
    th {
      color: #33325c;
    }
  }
  .generalInfo {
    display: flex;
    align-items: center;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;

    color: #33325c;
    .item {
      margin-right: 5%;
      &:first-of-type {
        font-weight: normal;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .searchBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    .totalsInfo {

      display: flex;
      align-items: center;

      color: #33325c;
      .businessesCount {
        margin-left: 15px;
        span {
          color: #5c25d3;
          font-weight: bold;
        }
      }
      .emailsCount {
        margin-left: 15px;
        span {
          color: #5c25d3;
          font-weight: bold;
        }
      }
    }
    .searchInput {
      background: #f9f9f9;
      border: 1px solid #efeded;
      box-sizing: border-box;
      border-radius: 50px;
      width: 400px;
      height: 40px;
      input {
        width: 400px;
        background: #F9F9F9
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      width: 215px;
      .csvButton {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 22px;
        color: #ff4843;
        background: rgba(255, 72, 67, 0.06);
        border-radius: 50px;
      }
      .exelButton {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 22px;
        color: #5c25d3;
        background: rgba(92, 37, 211, 0.06);
        border-radius: 50px;
      }
    }
  }
  .reportButton {
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;

    text-align: center;

    color: #33325c;
  }
  .deleteButton {
    background: rgba(255, 72, 67, 0.06);
  }
}
