.squareInfo {
  margin: 30px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100px;
  border-radius: 20px;
  .amount {
    font-weight: 900;
    font-size: 48px;
  }
  .kind {
    font-size: 14px;
    line-height: 17px;
  }
  .divider {
    width: 70px;
    height: 1px;
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    transform: rotate(90deg);
  }
}

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .message {
    width: 350px;
    font-size: 12px;
    color: #8790b1;
    margin-bottom: 50px;
    .openModalBtn {
      cursor: pointer;
    }
  }
  .text {
    font-size: 18px;
    color: #33325c !important;
  }
  .countBusinesses {
    font-size: 24px;
    color: #33325C;
    margin: 20px 0 40px;
  }
  .squareInfoGrey {
    @extend .squareInfo;
    background-color: #f4f6fa;
    .amount {
      color: #8790b1;
    }
    .kind {
      color: #8790b1;
    }
    .divider {
      border: 1px solid #e7eaf0;
    }
  }
  .squareInfoRed {
    @extend .squareInfo;
    background-color: #ff48430f;
    .amount {
      color: #ff4843;
    }
    .kind {
      color: #ff4843;
    }
    .divider {
      border: 1px solid #ff48431a;
    }
  }
}
