.container {
  z-index: 1000;
  opacity: 1;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  // enter from
  &.fade-enter {
    transition: opacity 0ms;
    opacity: 1;
  }
  
  // enter to
  &.fade-enter-active {
    opacity: 1;
  }

  // exit from
  &.fade-exit {
    transition: opacity 500ms;
    opacity: 1;
  }

  // exit to
  &.fade-exit-active {
    opacity: 0;
  }
}

.overlay {
  left:0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
