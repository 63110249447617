@import 'styles/variables';

.playContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .playIconCircle {
    background-color: white;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow {
      border-top: 5px solid transparent;
      border-left: 10px solid $primary-color;
      border-bottom: 5px solid transparent;
      margin-left: 2px;
    }
  }
}
