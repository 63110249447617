.containerHeader {
  display: flex;
  justify-content: space-between;
  .btnNewCampaign {
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    margin-left: 30px;
  }
  .loginWrapper {
    display: flex;
    align-items: center;
    .message {
      font-size: 14px;
      line-height: 22px;
      color: #8790b1;
      margin-right: 5px;
    }
    .label {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #33325c;
      margin-right: 10px;
    }
  }
}
