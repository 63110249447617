.ghostCard {
  height: 245px;
  width: 245px;
}

.list {
  display: flex;
  justify-content: flex-start; 
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.card {
  width: 245px;
  height: 245px;
  font-size: 12px;
  line-height: 20px;
  color: #33325C;
  text-align: justify;
}

.wrapperMessage {
  overflow: hidden;
  height: 100%;
}

.cardFooter {
  display: flex; 
  justify-content: space-between;
  padding: 0 15px;
  overflow: hidden;
}