.card {
    display: block;
    width: 300px;
    padding: 22px 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #F0F0F0;
    border-radius: 10px;
    margin: 10px;
    .cardHeader {
        color: #33325C;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 22px;
    }
    .statisticInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
