@mixin button-variant($color, $background) {
  color: $color;
  background-color: $background;

  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $color;
      background-color: darken($background, 10);
    }
  }
}

@mixin button-size($button-size, $icon-size ) {  
  width: $button-size;
  height: $button-size;
  font-size: $icon-size;
}
