.textArea {
  width: 400px;
  height: 200px!important;
  background: #F9F9F9;
  border-radius: 10px;
  border: none;
  resize: none;
  padding: 10px;
  outline: none;
  color: #33325C;
  font-size: 14px;
}