.message {
  font-size: 20px;
  color: white;
}
.button {
  margin-top: 30px;
  width: 200px;
  height: 50px;
  border: 1px solid white;
  font-weight: bold;
  font-size: 14px;
}
.image {
  max-width: 100%;
}