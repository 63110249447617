.container {
  position: relative;
  padding: 0 30px 30px;
  width: 900px;
  .title {
    position: absolute;
    font-size: 24px;
    color: #33325c;
  }
  .form {
    .formGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;
      width: 100%;
      .label {
        text-align: start;
        font-size: 14px;
        color: #33325c;
        flex: 1;
      }
      .input {
        width: 700px;
        flex: 2;
        background: #f9f9f9;
        box-shadow: none;
      }
    }
    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      font-weight: 900;
      font-size: 13px;
      .buttonSubmit {
        :hover {
          color: white;
        }
        .buttonIcon {
          margin-left: 10px;
        }
      }
    }
  }
}
