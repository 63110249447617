.dropdown {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown__menu {
  padding: 0.5rem;
}

.dropdown__menuItem {
  color: #9AA2CA;
  cursor: default;
  &:hover {
    background: none !important;
  }
  span {
    color: #33325C;
    margin-left: 0.3rem;
  }
}

.dropdown__avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.dropdown__fullname {
  margin: 0 0.2rem;
}

.progressBar {
  display: flex;
  align-items: center;
}
