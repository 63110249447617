@import '../ProfileTabs.module.scss';

.form {
  @extend .form;
  .uploadAvatarContainer {
    .dropzone {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #F4F6FA;
      border: 1px solid #F0F0F0;
      outline: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > .upload {
        margin-top: 5px;
        font-size: 10px;
        color: #BFC3D7;
      }
      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .message {
      text-align: center;
      margin-top: 20px;
      font-size: 10px;
      color: #BFC3D7;
    }
  }
  @extend .form__button;
  @extend .successResponse;
  @extend .wrongResponse;
}