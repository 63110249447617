.ghostCard {
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed #E4E4E4;
  border-radius: 10px;
  box-sizing: border-box;
  b {
    padding-top: 10px;
    font-size: 14px;
    color: #33325C;
  }
  &:hover,
  &:active {
    background-color: #fdfdfd;
    cursor: pointer;
  }
}