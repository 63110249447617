@import 'styles/variables.scss';
@import 'breakpoint';

.sectionPricingPlans {
  max-width: 1170px;
  margin: 0 auto;
  font-size: 14px;

  @include breakpoint(0 $xl) {
    padding: 90px 0 90px;
  }
  @include breakpoint(0 $sm) {
    padding: 40px 0 90px;
  }
  .plansTitle {
    text-align: center;
    font-size: 48px;
    font-weight: normal;
    margin: 0 0 20px;
    @include breakpoint(0 $xl) {
      font-size: 36px;
      margin-bottom: 40px;
    }
    @include breakpoint(0 $sm) {
      font-size: 24px;
    }
    strong {
      color: $primary-color;
    }
  }
  .plansTerms {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    @include breakpoint(0 $lg) {
      margin-bottom: 70px;
    }
    .planTermsButton {
      width: 200px;
      height: 60px;
      font-weight: normal;
      @include breakpoint(0 $lg) {
        width: 180px;
        height: 50px;
      }
      @include breakpoint(0 $sm) {
        font-size: 13px;
        width: 150px;
        padding: 15px;
      }
      &.white {
        color: #57577a;
      }
      &:first-child {
        border-radius: 30px 0 0 30px;
      }
      &:last-child {
        border-radius: 0 30px 30px 0;
      }
      strong {
        font-weight: 900;
      }
    }
  }

  .planTableWrapper {
    padding: 0 30px;
    margin: 0 auto;
    max-width: 1170px;
    position: relative;
    height: 660px;
    overflow-y: scroll;
    background-color: white;

    &::-webkit-scrollbar {
      background-color: white;
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: #f3f3f3;
      border-radius: 10px;
    }

    @include breakpoint(0 1269px) {
      padding: 0 40px 40px;
    }
    @include breakpoint(0 $xl) {
      padding: 0 20px 40px;
    }
    @include breakpoint(0 $sm) {
      padding: 0 0 40px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 26px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: $white-color;
      box-shadow: 4px 4px 50px rgba(154, 162, 202, 0.05);
      border-radius: 10px;
    }
    .planTableInner {
      overflow-x: auto;
      width: 100%;
      position: relative;
    }
  }
  .planTable {
    z-index: 2;
    margin: 26px 0 0;
    position: relative;
    width: 1090px;
    
    @include breakpoint(0 $sm) {
      width: 600px;
    }
    .planRow {
      display: flex;
      align-items: stretch;
      .planCol {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 209px;
        flex: 0 0 209px;
        padding: 20px 10px;
        font-weight: bold;
        min-height: 68px;
        border-bottom: 1px solid #f0f0f0;
        @include breakpoint(0 $sm) {
          width: 150px;
          flex: 0 0 150px;
          font-size: 13px;
        }
        &:nth-child(1) {
          width: 254px;
          flex: 0 0 254px;
          font-weight: normal;
          text-align: left;
          justify-content: flex-start;
          color: $text-color-pale-2;
          padding: 15px 10px 15px 40px;
          @include breakpoint(0 $lg) {
            order: 1;
            padding: 15px 10px 15px 30px;
          }
          @include breakpoint(0 $sm) {
            padding: 15px 10px 15px 20px;
            font-size: 12px;
            width: 159px;
            flex: 0 0 159px;
          }
          .tipView {
            position: relative;
            .icon {
              padding: 4px 5px 3px;
              cursor: pointer;
              svg {
                width: 10px;
              }
            }
            .tip {
              display: block;
              position: absolute;
              top: 8px;
              left: 10px;
              transform: translateY(-50%);
              z-index: 10;
              @include breakpoint(0 $sm) {
                left: 5px;
              }
              .tipContent {
                background: $white-color;
                border-radius: 10px;
                padding: 35px 25px;
                z-index: 0;
                position: relative;
                display: block;
                box-shadow: 0 5px 40px rgba(51, 50, 92, 0.1);
                width: 250px;
                color: #8790b1;
                @include breakpoint(0 $lg) {
                  padding: 20px;
                  width: 220px;
                }
                @include breakpoint(0 $sm) {
                  padding: 10px;
                  width: 150px;
                }
              }
              .tipArrow {
                display: block;
                width: 15px;
                height: 40px;
                background: url('../../assets/icons/tip.svg') 100% 50%
                  no-repeat;
                background-size: contain;
                position: absolute;
                top: 50%;
                margin-top: -20px;
                left: -15px;
                z-index: 1;
                filter: drop-shadow(-7px 0px 4px rgba(51, 50, 92, 0.04));
                @include breakpoint(0 $sm) {
                }
              }
            }
            &.isActive {
              .icon {
                opacity: 0.8;
              }
              .tip {
                display: block;
              }
            }
          }
        }
        &:nth-child(2) {
          @include breakpoint(0 $lg) {
            order: 3;
          }
        }
        &:nth-child(3) {
          @include breakpoint(0 $lg) {
            order: 4;
          }
        }
        &:nth-child(4) {
          background-color: #f7f4fd;
          @include breakpoint(0 $lg) {
            order: 2;
          }
        }
        &:nth-child(5) {
          @include breakpoint(0 $lg) {
            order: 5;
          }
        }
      }
      &.isRowGroup {
        color: $primary-color;

        .planCol {
          font-weight: 900;
          padding-top: 10px;
          padding-bottom: 10px;
          min-height: 48px;
          background: rgba(92, 37, 211, 0.05);
          &:nth-child(1) {
            color: $text-color-2;
          }
          &:nth-child(4) {
            .icon {
              margin-left: 10px;
              @include breakpoint(0 $lg) {
                margin-left: 10px;
              }
            }
          }
        }
      }
      &.planRowHeader {
        .planCol {
          flex-direction: column;
          justify-content: flex-start;
          padding: 32px 10px 50px;
          position: relative;
          .popularLabel {
            background: $primary-color;
            color: $white-color;
            padding: 7px 25px;
            line-height: 18px;
            border-radius: 3px;
            font-size: 13px;
            text-transform: uppercase;
            position: absolute;
            top: -16px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            @include breakpoint(0 $sm) {
              font-size: 12px;
              padding: 7px 15px;
            }
          }
          .planTitle {
            font-weight: bold;
            text-transform: uppercase;
            color: $primary-color;
            font-size: 20px;
            margin: 0px 0 5px;
            @include breakpoint(0 $sm) {
              margin: 20px 0;
            }
          }
          .planLogo {
            background: url('../../assets/img/price/price-item-bg.svg') 50% 100%
              no-repeat;
            background-size: contain;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 176px;
            height: 150px;
            margin-bottom: 15px;
            @include breakpoint(0 $sm) {
              width: 130px;
              height: 120px;
            }

            svg {
              margin-bottom: 20px;
              @include breakpoint(0 $sm) {
                transform: scale(0.8);
              }
            }
          }
          .planPrice {
            font-weight: bold;
            font-size: 36px;
            font-family: $font-family-heading;
            margin-bottom: 20px;
            @include breakpoint(0 $sm) {
              font-size: 30px;
              margin-bottom: 15px;
            }
          }
          &:nth-child(4) {
            background-color: #f7f4fd;
          }
        }
      }
      &.planRowFooter {
        position: relative;
        width: 1090px;
        background-color: white;
        .btn {
          padding-bottom: 6px;
          padding-top: 6px;
          padding-right: 10px;
          .btnContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            .circle {
              width: 34px;
              height: 34px;
              margin-left: 40px;
              background-color: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .arrow {
                transform: rotate(1deg);
              }
            }
          }
        }
        .currentPlan {
          width: 171px;
          height: 46px;
          background-color: #7cae491a;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #7CAE49;
          border-radius: 50px;
          display: flex;

          align-items: center;
          justify-content: center;
          .message {
            margin-left: 5px;
          }
        }
        .planCol {
          padding: 5px 5px;
          border: none;
        }
      }
      button {
        margin: 0 auto;
      }
    }
  }

  .planCustom {
    display: flex;
    padding: 23px 40px;
    border-radius: 10px;
    background: $white-color;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 4px 50px rgba(154, 162, 202, 0.05);
    @include breakpoint(0 $lg) {
      padding: 23px 30px;
    }
    @include breakpoint(0 $md) {
      flex-direction: column;
      padding: 23px 30px 30px;
    }
    .planCustomLabel {
      color: $primary-color;
      font-size: 18px;
      font-weight: 900;
      @include breakpoint(0 $md) {
        margin-bottom: 20px;
      }
    }
    .planCustomText {
      @include breakpoint(0 $md) {
        margin-bottom: 27px;
        text-align: center;
      }
      @include breakpoint(0 $sm) {
        font-size: 12px;
      }
    }
    .planCustomButton {
      padding: 7px 24px;
      line-height: 20px;
      font-size: 13px;
      border-radius: 17px;
    }
  }
}
.arrowContainer {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 15px;
  .arrow {
    transition: transform 0.1s;
    transform: rotate(-90deg);
  }
  .activeArrow {
    transition: transform 0.1s;
  }
}
.muted {
  opacity: 0.5;
}