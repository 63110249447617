.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  .buttonSearch {
    width: 150px;
    height: 50px;
    background: linear-gradient(180deg, #743DEC -2.5%, #3C25A4 165.63%);
    border-radius: 0px 50px 50px 0px;
    font-weight: 900;
    font-size: 14px;
    color: #FFFFFF;
  } 
}

.requestInfo {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
.message {
  width: 350px;
  font-size: 12px;
  color: #8790b1;
  .openModalBtn {
    cursor: pointer;
  }
}
}