@import 'styles/variables.scss';

.mainNav {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 1.11rem 1.11rem;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  .mainNavItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    vertical-align: middle;
    .NavItemBtn {
      margin-right: 20px;
    }
    .NavItem {
      margin: 0 30px 0 30px;
    }
    .mainNavItem {
      .item {
        display: flex;
        align-items: center;
        vertical-align: middle;
        cursor: default;
      }
      b {
        color: $primary-color;
        margin: 0 6px 0 6px;
      }
      .buttonUpgrade {
        :hover {
          color: white;
        }
      }
    }
  }
}
