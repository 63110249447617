.tabList {
  border: none;
  padding: 30px;
  margin: 0;
  .tabList__item {
    width: 115px;
    height: 40px;
    background-color: #F4F6FA;
    color: #BFC3D7;
    font-size: 14px;
    border: none;
    padding: 0;
    text-align: center;
    border-left: 1px solid #E9EDF5;
    box-sizing: border-box;
    padding-top: 9px;
    &:first-child {
      border-radius: 20px 0px 0px 20px;
    }
    &:last-child {
      border-radius: 0px 20px 20px 0px;
    }
  }
  .isSelected {
    background-color: rgba(92, 37, 211, 0.06);
    color: #5C25D3;
    outline: none;
    box-shadow: none;
    border: none;
  }
}
.form {
  width: 290px;
  margin: 0 auto;
  text-align: start!important;
  .form__button {
    margin-top: 50px;
    margin-bottom: 17px;
    text-align: center;
  }
  .successResponse {
    text-align: center;
    font-size: 14px;
    color: #8DD91F;
  }
  .wrongResponse {
    text-align: center;
    font-size: 14px;
    color: #FF4843;
  }
}