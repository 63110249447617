@import '../../styles/variables.scss';
@import '../../styles/mixins/button.scss';

.touchableButton {
  display: inline-block;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  transition-duration: $transition-duration;
}

.btn {
  @extend .touchableButton;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: 0;
  transition-duration: $transition-duration;
  @include button-size($button-size-md, $icon-size-md);
  @include button-variant($text-color-inverted, $primary-color);
}

.sm {
  @include button-size($button-size-sm, $icon-size-sm);
}
.md {
  @include button-size($button-size-md, $icon-size-md);
}

.primary {
  @include button-variant($button-primary-color, lighten($button-primary-color, 35));
}
.danger {
  @include button-variant($button-danger-color, lighten($button-danger-color, 28));
}
.success {
  @include button-variant($button-success-color, lighten($button-success-color, 35));
}
.default {
  @include button-variant($button-default-color, lighten($button-default-color, 18));
}
.light {
  @include button-variant($primary-dark-color, $button-light-color);
}
.secondary {
  @include button-variant($primary-dark-color, $light-gray-color);
}