.container {
  display: flex;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.content {
  flex: 1;
  margin-left: 300px;
}
