@import 'styles/variables';

.overlay {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(243, 246, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.modal {
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;

  text-align: center;
  position: relative;
  width: 500px;
  border: none;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.modalAfterOpen {
  opacity: 1;
}

.modalBeforeClose {
  opacity: 0;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.modalCloseButton {
  border: none;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.modalImage {
  padding-top: 20px;
  padding-bottom: 6px;
}

.modalTitle {
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 22px;
  padding: 0 50px 20px;
  color: #33325C;
}

.modalMessage {
  font-size: 12px;
  color: #8790B1;
  padding: 0 70px;
}

.modalButton {
  margin: 50px 8px 20px;
}