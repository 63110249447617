.wrapper {
  display: flex;
  flex-wrap: wrap;
  .profileCard__wrapper {
    margin-top: 10px;
    text-align: center;
  }
  .profileCard__fullName {
    color: #33325c;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center !important;
  }
  .profileCard__email {
    color: #33325c;
    font-size: 12px;
    margin-top: 8px;
    line-height: 20px;
  }

  .currentPlan__info {
    color: #5c25d3;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 17px;
  }
}
