@import "../../styles/variables.scss";

.logoContainer {
  .logoImage {
    max-width: 18% !important;
  }
}

.allInfo {
  margin-top: 3%;
  .info {
    display: flex;
    justify-content: center;
    font-family: Brutal Type;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #33325c;
  }

  .infoText {
    margin-top: 1%;
    text-align: center;
    max-width: 20% !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    color: #33325c;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 12%;
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

.terms {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 34px;
  color: #33325c;
  margin-top: calc(25vh - 80px);
  .termsInfo {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  .links {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    a {
      color: #33325c !important;
    }
  }
}
