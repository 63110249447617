@import 'styles/variables.scss';

.button {
  width: 130px;
  height: 40px;
  border-radius: 50px;
  font-weight: 900;
  font-size: 13px;
  align-self: flex-end;
}

.container {
  margin-left: 90px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .cardContainer {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    .card {
      display: flex;
      flex-direction: column;
      width: 45%;
      min-width: 500px;
      text-align: center;
      .title {
        font-weight: 900;
        font-size: 30px;
        color: #33325c;
        margin: 20px 0;
      }
      .message {
        width: 70%;
        font-size: 12px;
        color: #8790b1;
        margin-bottom: 20px;
      }
      .form {
        display: flex;
        text-align: start;
        align-items: center;
        margin-bottom: 20px;
        input {
          width: 250px;
        }
        .saveButton {
          @extend .button;
          margin-left: 20px;
          .buttonIconStyle {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .footerContainer {
    display: flex;
    justify-content: space-between;
    label {
      font-weight: 900;
      font-size: 13px;
      line-height: 22px;
      text-decoration: underline;
      & > * {
        color: $danger-color!important;
      }
    }
    .nextButton {
      @extend .button;
      margin-right: 40px;
    }
  }
}
