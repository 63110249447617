.mainDataSection {
  display: flex;
  margin-bottom: 20px;
  .input {
    margin-right: 20px;
  }
  }
.infoContainer {
  .titlePage {
    margin-bottom: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;

    text-align: center;

    color: #33325C;
  }
  .infoText {
    margin-bottom: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    
    color: #8790B1;
  }
}
.inputSection {
  padding: 30px;
text-align: left;
  background: #F4F6FA;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  .title {
    margin-bottom: 10px;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #33325C;
  }
  
  .buttonContainer {
    width: 100%;
    text-align: center;
  }
}
.submitSection {
  display: flex;
  align-items: center;
  
  .textArea {
    width: 600px;
    margin-right: 10px;
    background-color: #FFFFFF;
  }
}
