.rc-collapse {
  background-color: white;
  border: none;
}
 .rc-collapse-header {
  border: none;
  padding: 0!important;
  outline: none;
}
.rc-collapse-item {
  border: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  position: relative;
}
.rc-collapse-content {
  margin: 0;
  padding: 0;
}
.rc-collapse > .rc-collapse-item {
  border-top: none;
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 0;
  margin-bottom: 0;
}
