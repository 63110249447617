.container {
  margin: 0px 20px 0 50px;
  .message {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #33325C;
    margin-bottom: 50px;
  }
  .cards {
    display: flex;
    .chartWrapper {
      display: flex;
      flex-direction: column;
      .barChartLabels {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}