@import '../../styles/variables.scss';
@import '../../styles/mixins/button.scss';

.touchableButton {
  display: inline-block;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  transition-duration: $transition-duration;
  :hover {
    color: $primary-color;
  }
}

.btn {
  @extend .touchableButton;
  padding: 0.77em 2.7em;
  border-radius: 1.77em;
  font-weight: bold;
  font-size: 1.11em;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: 0;
  transition-duration: $transition-duration;
  @include button-variant($text-color-inverted, $primary-color);
}

.inverted {
  @include button-variant($danger-color, $light-red-color);
}
.primary {
  @include button-variant($text-color-inverted, $primary-color);
}
.secondary {
  @include button-variant($text-color-inverted, $secondary-color);
}
.success {
  @include button-variant($text-color-inverted, $success-color);
}
.danger {
  @include button-variant($text-color-inverted, $danger-color);
}
.transparent {
  @include button-variant($primary-color, $transparent-color);
}
.cancel {
  @include button-variant($button-default-color, $default-color);
}
.default {
  @include button-variant($text-color-inverted, $light-gray-color);
}
.whitePale {
  @include button-variant($text-color-2, $white-color);
}