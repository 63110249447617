.progressContainer {
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    position: absolute;
  }
  .data {
    margin: 0 auto;
    color: #5C25D3;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
  }
}