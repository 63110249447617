@import 'styles/variables';

title {
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 36px;
  color: #33325C;
  margin-bottom: 80px;
}
.form {
  width: 290px;
  margin-left: 55px; 
  text-align: start;
  .footerForm {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    & > * {
      margin: 0 15px;
    }
  }
  .buttonContainer {
    text-align: center;
    .button {
      width: 200px;
      height: 50px;
      font-size: 14px;
      color: white;
      margin: 0 auto;
    }
  }
}