.videoList {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 30px;
   .videoItem {
    width: 300px;
    height: 300px;
    box-shadow: 0px 4px 20px #F0F0F0;
    border-radius: 10px;
    background: #fff;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    box-sizing: border-box;
    .videoItem__button {
      display: block;
      background: none;
      border: none;
      outline: 0;
      padding: 0;
      cursor: pointer;
      position: relative;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      .imgTutorial {
        width: 300px;
        object-fit: contain;
        padding: 8px;
        box-sizing: border-box;
      }
    }
    .videoItem__info {
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .infoTitle {
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #33325C;
      }
      .infoDescription {
        text-align: justify;
        margin-top: 12px;
        font-size: 12px;
        color: #8790B1;
      }
    }
  }
}
