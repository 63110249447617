.historyWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  .tutorials {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .message {
      margin-right: 10px;
      font-size: 10px;
      line-height: 22px;
      color: #33325C;
    }
  }
  .btnTutorials {
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    .btnIcon {
      margin-left: 5px;
    }
  }
}