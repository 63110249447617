@import "styles/variables";
@import 'breakpoint';

.primarySection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.secondarySection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;
  color: $text-color-inverted;
  text-align: center;
  position: relative;
  .title {
    font-family: $font-family-heading;
    position: absolute;
    top: 57px;
    left: 50px;
    font-size: 24px;
    color: #FFFFFF;
  }
}

.fillHeight {
  @include breakpoint($md) {
    height: 100vh;
  }
  // min-height: 100% !important;
  // height: 100% !important;

}
