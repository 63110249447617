.inputContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.iconShowingPassword {
  position: absolute;
  color: #E0E0E0;
  top: 50px;
  right: 20px;
}