.container {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 22px;
  color: #33325C;
  .colorLabel {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  .nameLabel {
    margin-left: 5px;
  }
  .percentLabel {
    margin-left: 15px;
    font-weight: bold;
  }
}