

.titleContainer {
  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;
  vertical-align: middle;

.title {
  text-align: left;

  margin: 30px 0px 30px 30px;

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  
  color: #33325C;
}
}