@import 'font-face';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import 'variables';

html, body {
  font-size: 18px;
  font-family: $font-family, sans-serif;
  margin: 0;
}

html, body, #root {
  height: 100%;
  background-color: $background-color;
}

button, input, optgroup, select, textarea {
  font-family: $font-family, sans-serif;
}

p, ul {
  line-height: 1.5;
  margin-top: 0;
}

a {
  transition-duration: $transition-duration;
  color: $text-color;
  text-decoration: none;
  &:hover {
    color: $primary-color;
  }
}

.btn {
  &:disabled:hover {
    box-shadow: none;
    transform: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
