@import 'styles/variables';

.container {

  // #google-oauth2-btn {
  //   display: none;
  // }

  .invisibleButton {
    display: none;
  }

  .title {
    font-family: $font-family-heading;
    font-weight: bold;
    font-size: 36px;
    color: #33325C;
    margin-bottom: 40px;
  }
  .socialButtons {
    margin-bottom: 35px;
    .socialButton {
      margin: 0 20px;
    }
  }
  .dividerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .divider {
      width: 180px;
      margin: 0;
      background-color: #DFE2F0;
      height: 1px;
      border: none;
    }
    .text {
      padding: 0 10px;
      font-size: 12px;
      color: #8790B1;
    }
  }
  .form {
    width: 290px;
    margin-left: 55px;
    text-align: start;
    .recaptcha {
      margin-top: 15px;
      margin-left: -6px;
    }
    .formFooter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 0;
      & > * {
        margin: 5px 0;
      }
    }
    .buttonContainer {
      text-align: center;
      margin-top: 20px;
      .button {
        width: 200px;
        height: 50px;
        font-weight: bold;
        font-size: 14px;
        color: white;
      }
    }
  }
}
