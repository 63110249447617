@import 'styles/variables';

.title {
  font-family: $font-family-heading;
  font-weight: bold;
  font-size: 36px;
  color: #33325C;
  margin-bottom: 50px;
}
.form {
  width: 290px;
  text-align: start;
  margin: 0 auto;
  .buttonContainer {
    margin-top: 30px;
    text-align: center;
    .button {
      width: 200px;
      height: 50px;
      font-size: 14px;
      color: white;
      margin: 0 auto;
    }
  }
}