.uploadFileContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > * {
    margin-left: 10px;
  }
  .dropzone {
    width: 30px;
    height: 30px;
    background: url('../../../assets/icons/attachment.svg') no-repeat center;
    border: 0.5px solid #EFEDED;
    border-radius: 5px;
    background-color: #F9F9F9;
    outline: none;
    cursor: pointer;
  }
  .fileContainer {
    width: 110px;
    height: 36px;
    color: #33325C;
    display: flex;
    align-items: center;
    background: #F9F9F9;
    border: 0.5px solid #EFEDED;
    box-sizing: border-box;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    .wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      .fileInfoContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        position: relative;
        height: 100%;
        .fileName {
          white-space: nowrap;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .fileSize {
          font-weight: 300;
          font-size: 6px;
        }
      }
    }
    .deleteBtn {
      border: none;
      cursor: pointer;
      margin-left: auto;
      padding: 0 0 0 5px;
      background-color: #F9F9F9;
      & > svg {
        height: 15px;
      }
    }
  }
}
