@import '../../styles/variables.scss';

.header {
    display: flex;
    justify-content: space-around;
    .deleteButton {
      display: flex;
      flex-wrap: wrap;

      font-family: Proxima Nova;
      font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 22px;

        border-spacing: 0;

        text-decoration: underline;
        white-space: nowrap;
        color: #5C25D3;

    }
}