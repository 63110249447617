.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px #F0F0F0;
  background-color: white;
  position: relative;
  margin: 10px;
  .cardHeader {
    display: inline; 
    .cardHeader__button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .cardHeader__title {
      position: absolute;
      font-size: 16px;
      top: 30px;
      left: 30px;
    }
  }
  .cardImage {
    height: 116px;
    width: 116px;
    border-radius: 50%;
  }
  .card__footer {
    color: #8790B1;
    margin-top: 16px;
    font-size: 11px;
    line-height: 18px;
  }
}
