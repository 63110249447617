
@import "node_modules/normalize-scss/sass/normalize/import-now";

$hamburger-padding-x           : 10px;
$hamburger-padding-y           : 10px;
$hamburger-layer-width         : 30px;
$hamburger-layer-height        : 3px;
$hamburger-layer-spacing       : 5px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4px;
$hamburger-types: (squeeze);
@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import 'global';
