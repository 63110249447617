@import '../ProfileTabs.module.scss';

.form {
  @extend .form;
  @extend .form__button;
  .wrapperForgotPassword {
    display: flex;
    justify-content: flex-end;
    .form__fieldName_forgotPassword {
      font-size: 12px;
      color: #33325C;
      text-decoration-line: underline;
      align-self: flex-end;
      margin-top: 10px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  @extend .successResponse;
  @extend .wrongResponse;
}