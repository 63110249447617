.tabList {
  border: none;
  padding: 20px 0 50px 30px;
  margin: 0;
  .tabList__item {
    width: 130px;
    height: 50px;
    background-color: #F9F9F9;
    color: #8790B1;
    font-size: 20px;
    border: none;
    padding: 0;
    text-align: center;
    border-bottom: 2px solid #E6E6E6;
    box-sizing: border-box;
    padding-top: 9px;
  }
  .isSelected {
    color: #5C25D3;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #5C25D3;
  }
}