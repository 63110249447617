.container {
  display: flex;
  width: 920px;
  justify-content: center;
  align-items: center;
  // padding: 5px;
  margin-left: 45px;
  background: white;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  .subjectField {
    width: 400px;
    background-color: #F9F9F9;
  }
  .buttonContainer {
    margin-top: 42px;
    text-align: center;
  }
}
