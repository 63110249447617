.container__item {
  padding: 20px;
  width: 15%;
  &:first-of-type {
    width: 5% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      font-size: 18px;
    }
  }
  &:last-of-type {
    font-size: 14px;
    color: #bec3dc;
  }
}

.fieldName {
  color: #8790b1;
  font-size: 12px;
}

.fieldValue {
  color: #33325c;
  font-size: 20px;
  line-height: 22px;
}
