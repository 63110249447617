.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 150px;

.container {
  width: 600px;
  height: 331px;

  display: flex;
  flex-direction: column;

  background: white;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  text-align: center;

  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 18px;
    margin-top: 50px;
  
    color: #33325C;
    }
  .description {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    margin-top: 10px;

    color: #8790B1;
  }
  .advice {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    margin-top: 30px;

    color: #33325C;
  }
  .buttonsList {
    margin-top: 10px;

    .button {
      width: 255px;
      height: 50px;

      margin-bottom: 10px;

      .buttonText {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
      
        color: #000000;
      }
    }
  }
}
}

