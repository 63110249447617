@import '../ProfileTabs.module.scss';

.form {
  @extend .form;
  @extend .form__button;
  .form__phoneGroup {
    display: flex;
    justify-content: 'space-between';
    align-items: 'center';
  }
  .form__selectCode {
    width: 112px;
    box-shadow: 0px 4px 40px rgba(51, 50, 92, 0.05);
  }
  .socialButtons {
    margin: 0 auto 35px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    .socialButtonContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      .socialButton {
        margin: 0 0 5px;
      }
      .mutedSocialButton {
        margin: 0 0 5px;
        & > svg {
          color: #e6e6e6;
        }
      }
      .disconnectLabel {
        font-size: 10px;
        line-height: 10px;
        color: #ff4843;
      }
      .connectLabel {
        font-size: 10px;
        line-height: 10px;
        color: #5c25d3;
      }
      .connectIcon {
        position: absolute;
        right: 2px;
        top: 35px;
        font-size: 12px;
      }
    }
  }
  @extend .successResponse;
  @extend .wrongResponse;
}
